
import {
  captureQualFlowType,
  captureUniqueId,
  captureUserInfo,
  emitEvents,
  logoutSession,
} from '@/analytics/index';
import Notifications from '@/components/notifications/Notifications.vue';
import bus from '@/lib/event_bus';
import { supportedLanguages, updateLanguage } from '@/locales';
import strings from '@/locales/en';
import qs from 'qs';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { daysToExpiry } from './lib/consts';
import { isEmpty } from './lib/helpers';
import { updateLayoutConfig } from './lib/layout_config';
import { isCustomerAuthenticatedSync } from './lib/login_utils';
import {
  decodeJsonWebToken,
  getBigCookie,
  getGeolocation,
  getGeolocationPermissionStatus,
  isMicroappsEnabled,
  setCookie,
  getUtmSourceFromHost,
} from './lib/utils';
import { setQualFlowType } from './store/actions/app_config';
import { retrieveBigCookie } from './store/actions/customer';
import { authState } from './store/getters/auth';
import { customerDetails } from './store/getters/customer';
declare const google: any;

@Component({
  components: {
    Notifications,
    NetworkNotification: () => import('@/components/NetworkNotification.vue'),
    Loader: () => import('@/components/Loader.vue'),
  },
})
export default class App extends Vue {
  private language: string = 'en';
  private qualStrings: any = strings.qual;
  private aipStrings: any = strings.aip;
  private cmStrings: any = strings.cm;
  private networkStatus: string = 'online';
  private showNetworkNotification: boolean = false;
  private loaderData: { [key: string]: string | boolean } = {
    show: false,
    type: 'local',
    message: 'Loading...',
  };

  private get isGoogleSigninEnabled() {
    return process.env.VUE_APP_GOOGLE_SIGNIN_ENABLED === 'true';
  }

  private created() {
    bus.$on('syncEvent', emitEvents);
    bus.$on('capture-id', captureUniqueId);
    bus.$on('capture-user-info', captureUserInfo);
    bus.$on('user-logout', logoutSession);
    bus.$on('capture-qual-flow-type', captureQualFlowType);
    bus.$on('change-language', (language: string) => {
      if (supportedLanguages.includes(language)) {
        this.language = language;
      }
    });
    bus.$on('refresh-big-cookie', getBigCookie);
    bus.$on('loader', this.handleLoader);
    bus.$on('terminate-app-permission-popup', this.terminateAppPermissionPopup);
    this.networkStatus = navigator.onLine ? 'online' : 'offline';
    this.showNetworkNotification = !navigator.onLine;
    window.addEventListener('online', () => {
      this.updateNetworkConnStatus(true);
    });
    window.addEventListener('offline', () => {
      this.updateNetworkConnStatus(false);
    });
    window.addEventListener('load', this.trackInitialLoad);
    this.checkAndRefreshApplication();
    if ((window as any).google && this.isGoogleSigninEnabled) {
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: this.handleCredentialResponse,
        native_callback: this.handleCredentialResponse,
        context: 'signin',
        use_fedcm_for_prompt: true,
      });
    }
  }

  private async mounted() {
    this.setLayout();
    const geolocationPermissionStatus = await getGeolocationPermissionStatus();
    if (
      geolocationPermissionStatus === 'granted' &&
      isCustomerAuthenticatedSync(authState())
    ) {
      getGeolocation(null, false);
    }
  }

  private updateNetworkConnStatus(connected: boolean) {
    if (connected) {
      this.networkStatus = 'online';
      if (this.showNetworkNotification) {
        bus.$emit('syncEvent', 'WEB.APPLICATION.CONNECTED', {
          nonInteraction: true,
        });
        setTimeout(() => {
          this.showNetworkNotification = false;
        }, 2000);
      }
    } else {
      this.networkStatus = 'offline';
      bus.$emit('syncEvent', 'WEB.APPLICATION.OFFLINE', {
        nonInteraction: true,
      });
      this.showNetworkNotification = true;
    }
  }

  private handleLoader(data: { [key: string]: string | boolean } = {}) {
    this.loaderData.show = data.show || false;
    this.loaderData.type = data.type || 'local';
    this.loaderData.message = data.message || 'Loading...';
  }

  private async checkAndRefreshApplication() {
    const isAuthenticated = isCustomerAuthenticatedSync(authState());
    if (isAuthenticated && isEmpty(customerDetails())) {
      await retrieveBigCookie(true, 'App:checkAndRefreshApplication');
    }
  }

  private terminateAppPermissionPopup(data: any) {
    bus.$emit('terminate-permission-popup', data);
  }

  private trackInitialLoad() {
    const trackParams = this.buildTrackParameters();
    bus.$emit('syncEvent', 'Referrer Set', {
      ...trackParams,
      nonInteraction: true,
    });
    bus.$emit('syncEvent', 'WEB.APPLICATION.LOADED', {
      ...trackParams,
      nonInteraction: true,
    });
  }

  private buildTrackParameters() {
    const queryParts = window.location.href.split('?');
    let query;
    let queryParams = {};
    let referrerParams: any = {};
    let trackParams: any = {
      siteUrl: window.location.href,
      userAgentString: navigator.userAgent,
    };
    if (queryParts.length > 1) {
      query = queryParts[1];
    }
    if (query) {
      queryParams = qs.parse(query, { ignoreQueryPrefix: true });
    }
    if (window.document.referrer.trim()) {
      let referQuery;
      let referrerQueryParams = {};
      referrerParams.referrerUrl = window.document.referrer;
      const referrerQueryParts = window.document.referrer.split('?');
      if (referrerQueryParts.length > 1) {
        referQuery = referrerQueryParts[1];
      }
      if (referQuery) {
        referrerQueryParams = qs.parse(referQuery, { ignoreQueryPrefix: true });
      }
      referrerParams = referrerQueryParams
        ? Object.assign({}, referrerParams, referrerQueryParams)
        : referrerParams;
    }
    if (!isEmpty(queryParams) || !isEmpty(referrerParams)) {
      if (!isEmpty(queryParams)) {
        trackParams = { ...trackParams, ...queryParams };
      }
      if (!isEmpty(referrerParams)) {
        trackParams = { ...trackParams, ...referrerParams };
      }
    }
    trackParams = { ...trackParams };
    const expiryDate = daysToExpiry * 24 * 60 * 60;
    const utmSource = getUtmSourceFromHost() || 'moneytap-webqual';
    trackParams.utm_source = utmSource;
    setCookie(trackParams, expiryDate);
    if (!isMicroappsEnabled()) {
      if (
        process.env.VUE_APP_NEW_QUAL_UTM_SOURCES &&
        process.env.VUE_APP_NEW_QUAL_UTM_SOURCES.split(',').includes(utmSource)
      ) {
        setQualFlowType({ payload: 'NEW' });
      } else {
        setQualFlowType({ payload: 'NEW_CONTROL' });
      }
    } else {
      setQualFlowType({ payload: 'NEW_CONTROL' });
    }
    return trackParams;
  }

  private async handleCredentialResponse(credentialResponse: any) {
    if (credentialResponse && credentialResponse.credential) {
      const decodedJWT = await decodeJsonWebToken(
        credentialResponse.credential
      );
      if (!isEmpty(decodedJWT)) {
        bus.$emit('gmail-signin-response', {
          success: true,
          jwt: credentialResponse.credential,
          userName: decodedJWT.email,
          isEmailVerified: true,
          currentRoute: this.$route.name,
        });
      } else {
        bus.$emit('gmail-signin-response', {
          success: false,
          currentRoute: this.$route.name,
        });
      }
    }
  }

  @Watch('language')
  private switchLanguage(newValue: string) {
    updateLanguage(this.language);
    import(`@/locales/${newValue}`)
      .then((stringConstants) => {
        this.qualStrings = stringConstants.default.qual;
        this.aipStrings = stringConstants.default.aip;
        this.cmStrings = stringConstants.default.cm;
      })
      .catch((err) => console.log(err));
  }

  @Watch('microapps')
  private setLayout() {
    if (isMicroappsEnabled()) {
      updateLayoutConfig('GPAY_LAYOUT');
    }
  }

  private beforeDestroy() {
    try {
      bus.$off('loader');
      bus.$off('syncEvent');
      bus.$off('change-language');
      bus.$off('refresh-big-cookie');
      bus.$off('capture-id');
      bus.$off('capture-user-info');
      bus.$off('user-logout');
      bus.$off('capture-qual-flow-type');
      window.removeEventListener('load', this.trackInitialLoad);
      window.removeEventListener('online', () => this.updateNetworkConnStatus);
      window.removeEventListener('offline', () => this.updateNetworkConnStatus);
    } catch {
      console.log('Error while turning off listeners');
    }
  }
}
