import eventBus from '@/lib/event_bus';
import { get, isEmpty } from '@/lib/helpers';
import { getNavigationRoute, updateLayoutConfig } from '@/lib/layout_config';
import { layoutConfig } from '@/lib/layout_consts';
import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { retrieveBigCookie } from '@/store/actions/customer';
import { authState } from '@/store/getters/auth';

const beforeEnter = async (to: any, from: any, next: any) => {
  updateLayoutConfig('CREDIT_MANAGEMENT_LAYOUT');
  try {
    eventBus.$emit('loader', {
      show: true,
      message: 'Loading...',
    });
    if (isCustomerAuthenticatedSync(authState())) {
      await retrieveBigCookie(false, 'cmRoutes:beforeEnter');
    }
    let navigationRoute;
    if (to && to.name) {
      navigationRoute = getNavigationRoute(undefined, undefined, to.name);
    } else {
      navigationRoute = getNavigationRoute();
    }
    const { targetRoute = '', query = {}, params = {} } = navigationRoute;
    if (targetRoute && targetRoute !== to.name) {
      next({
        name: targetRoute,
        query: !isEmpty(query) ? query : undefined,
        params: !isEmpty(params) ? params : undefined,
      });
    } else {
      next();
    }
  } catch (err) {
    console.log('Error while get customer details', err);
    next();
  } finally {
    eventBus.$emit('loader');
  }
};

export const cmRoutes = {
  path: '/cm',
  name: get(layoutConfig, 'CREDIT_MANAGEMENT_LAYOUT.routes.aipHome', ''),
  component: () =>
    import(
      /* webpackChunkName: "cm-credit-layout" */ '@/layouts/CreditLayout.vue'
    ),
  meta: { requiresAuth: true },
  beforeEnter,
  children: [
    {
      path: 'tenure-selection',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.tenureSelection',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "cm-tenure-select" */ '@/views/cm/TenureSelection.vue'
        ),
    },
    {
      path: 'confirm-transaction',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.confirmTransaction',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "cm-confirm-transact" */ '@/views/cm/ConfirmTransaction.vue'
        ),
    },
    {
      path: 'transaction-response',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.transactionResponse',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "cm-transact-resp" */ '@/views/cm/TransactionResponse.vue'
        ),
    },
    {
      path: 'emi-repayment',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.emiRepayment',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "cm-repay-emi" */ '@/views/cm/EmiRepayment.vue'
        ),
    },
    {
      path: 'sign-document-hdbfs',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.signDocumentHdbfs',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "cm-sign-doc-hdbfs" */ '@/views/signDocuments/hdbfs/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-document-muskmelon',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.signDocumentMuskmelon',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "cm-sign-doc-mm" */ '@/views/signDocuments/muskmelon/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-document-cholamandalam',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.signDocumentCholamandalam',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "cm-sign-doc-ts" */ '@/views/signDocuments/cholamandalam/SignDocuments.vue'
        ),
    },
    {
      path: 'laf-document-cholamandalam',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.signDocumentCholamandalamLaf',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "cm-sign-doc-ts" */ '@/views/signDocuments/cholamandalam/LafSignDocument.vue'
        ),
    },
    {
      path: 'sign-document-incred',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.signDocumentIncred',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "cm-sign-doc-ts" */ '@/views/signDocuments/incred/SignDocuments.vue'
        ),
    },
    {
      path: 'laf-document-incred',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.signDocumentIncredLaf',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "cm-sign-doc-ts" */ '@/views/signDocuments/incred/LafSignDocuments.vue'
        ),
    },
    {
      path: 'key-fact-statement',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.keyFactStatement',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "key-fact-statement" */ '@/views/KeyFactStatement.vue'
        ),
    },
    {
      path: 'setup-emandate',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.setupEmandate',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "cm-setup-emandate" */ '@/views/SetupEmandate.vue'
        ),
    },
    {
      path: 'beneficiary-details',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.beneficiaryDetails',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-bene-details" */ '@/views/BeneficiaryDetails.vue'
        ),
    },
    {
      path: 'schedule-appointment',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.scheduleAppointment',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-schedule-appointment" */ '@/views/ScheduleAppointment.vue'
        ),
    },
    {
      path: 'submit-bank-proof',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.submitBankProof',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-bank-statement" */ '@/views/SubmitBankProof.vue'
        ),
    },
    {
      path: 'laf-document-aspire',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.signDocumentAspireLaf',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "cm-sign-doc-ts" */ '@/views/signDocuments/aspire/LafSignDocuments.vue'
        ),
    },
    {
      path: 'laf-document-komac',
      name: get(
        layoutConfig,
        'CREDIT_MANAGEMENT_LAYOUT.routes.signDocumentKomacLaf',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "cm-sign-doc-ts" */ '@/views/signDocuments/komac/LafSignDocuments.vue'
        ),
    },
  ],
};
